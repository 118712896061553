import React from "react";
import { graphql, StaticQuery } from 'gatsby';
import Layout from '../components/Layout';
import Cover from '../components/home/Cover';
import Seo from '../components/Seo';

let option;

const layoutOption = () => {
  return Math.floor(Math.random() * (5 - 1 + 1) + 1);
}

class Home extends React.Component {

  componentDidMount() {
    const cover = document.querySelector( '.cover-image' );
    option = layoutOption();
    cover.classList.add( 'cover-option-' + option );
  }

  render() {
    return (
      <>
        <StaticQuery 
          query={
            graphql`
              query Home {
                allWpPage(filter: {id: {eq: "cG9zdDo2"}}) {
                  edges {
                    node {
                      title
                      content
                      id
                      coverImages {
                        coverImages {
                          images {
                            desktop {
                              localFile {
                                childImageSharp {
                                  gatsbyImageData(placeholder: NONE)
                                }
                              }
                              altText
                            }
                          }
                        }
                      }
                      footerImage {
                        footerImageMobile {
                          localFile {
                            childImageSharp {
                              gatsbyImageData(placeholder: NONE)
                            }
                          }
                        }
                      }
                    }
                  }
                }
                allWpResearch(filter: {id: {eq: "cG9zdDoyMw=="}}) {
                  edges {
                    node {
                      uri
                    }
                  }
                }
              }
            `
          }
          render={ ( home ) => (
            <>
              <Layout>
                <Seo scheme={ 'scheme:secondary' } title={ home.allWpPage.edges[0].node.title } />
                <article className={ 'story' }>
                  <Cover 
                    hackneyVillage={ home.allWpResearch.edges[0].node.uri } 
                    footer={ home.allWpPage.edges[0].node.footerImage } 
                    coverGallery={ home.allWpPage.edges[0].node.coverImages.coverImages }
                    content={ home.allWpPage.edges[0].node.content }
                  />
                </article>
              </Layout>
            </>
          )}
        />
      </>
    );
  }

}

export default Home;