import React from 'react';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import '../../styles/components/statement.scss';

export default function Statement( props ) {
	return (
		<section className={ 'statement mobile' }>
            <div className={ 'flex spacious' } dangerouslySetInnerHTML={{ __html: props.copy }}></div>
		</section>
	);
}