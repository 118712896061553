import React, { useState, useEffect } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Link } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import ResearchNavigation from '../navigation/ResearchNav';
import Footer from '../utils/Footer';
import Statement from './Statement';

import 'swiper/css';
import 'swiper/css/effect-fade';
import '../../styles/components/cover-image.scss';
import '../../styles/components/cover-statement.scss';

import SwiperCore, {
  Autoplay, 
  EffectFade
} from 'swiper';

let optionIndex;
let a1, a2, a3, a4, a5, a6, a7, a8;
let playing = true;

SwiperCore.use([Autoplay]);

// const arrangement = () => {
//   let group1 = document.querySelector( '[data-group="1"]' );
//   let group2 = document.querySelector( '[data-group="2"]' );
//   let group3 = document.querySelector( '[data-group="3"]' );
//   let group4 = document.querySelector( '[data-group="4"]' );
//   let group5 = document.querySelector( '[data-group="5"]' );
//   let group6 = document.querySelector( '[data-group="6"]' );
//   let group7 = document.querySelector( '[data-group="7"]' );
//   let group8 = document.querySelector( '[data-group="8"]' );

//   // a1 = group1.animate({
//   //   transform: "translateY(-37.11vh)",
//   //   transform: "translateY(0px)",
//   // }, {
//   //   fill: 'forwards',
//   //   easing: 'linear',
//   //   duration: 500,
//   //   iterations: 1
//   // });

// //   a1 = group1.animate(
// //     [{ translate: '0 -37.11vh' }, { translate: '0 0' }],
// //     {
// //         easing: 'linear',
// //         duration: 500,
// //         fill: 'forwards'
// //     }
// // );
  
//   function setAnimation( animation, group ) {
//     animation.finished.then(() => {
//       animation.commitStyles();
//       animation.cancel();
//       group.animate([{ transform: 'translate(0px, 0px)' }, { transform: 'translate(0px, 0px)' }], {
//           fill: 'forwards',
//           duration: 0,
//           iterations: 0
//       })
//     });
//   }


//   a1 = group1.animate(
//       [{ transform: 'translate(0px, -37.11vh)' }, { transform: 'translate(0px, 0px)' }],
//       {
//           fill: 'forwards',
//           easing: 'linear',
//           duration: 500,
//           iterations: 1
//       }
//   );
//   a1.finished.then(() => {
//     a1.commitStyles();
//     a1.cancel();
//     // console.log("A");
//       group1.animate([{ transform: 'translate(0px, 0px)' }, { transform: 'translate(0px, 0px)' }], {
//           fill: 'forwards',
//           duration: 0,
//           iterations: 0
//       })
//   });

//   a2 = group2.animate(
//       [{ transform: 'translate(0px, -26.75vh)' }, { transform: 'translate(0px, 0px)' }],
//       {
//           fill: 'forwards',
//           easing: 'linear',
//           duration: 500,
//           iterations: 1
//       }
//   );
  
//   a2.finished.then(() => {
//     a2.commitStyles();
//     a2.cancel();
//     // console.log("A");
//       group2.animate([{ transform: 'translate(0px, 0px)' }, { transform: 'translate(0px, 0px)' }], {
//           fill: 'forwards',
//           duration: 0,
//           iterations: 0
//       })
//   });

//   a3 = group3.animate(
//       [{ transform: 'translate(0px, -35vh)' }, { transform: 'translate(0px, 0px)' }],
//       {
//           fill: 'forwards',
//           easing: 'linear',
//           duration: 500,
//           iterations: 1
//       }
//   );
  
//   a3.finished.then(() => {
//     a3.commitStyles();
//     a3.cancel();
//     // console.log("A");
//       group3.animate([{ transform: 'translate(0px, 0px)' }, { transform: 'translate(0px, 0px)' }], {
//           fill: 'forwards',
//           duration: 0,
//           iterations: 0
//       })
//   });

//   a4 = group4.animate(
//       [{ transform: 'translate(0px, -16.66vh)' }, { transform: 'translate(0px, 0px)' }],
//       {
//           fill: 'forwards',
//           easing: 'linear',
//           duration: 500,
//           iterations: 1
//       }
//   );
  
//   a4.finished.then(() => {
//     a4.commitStyles();
//     a4.cancel();
//     // console.log("A");
//       group4.animate([{ transform: 'translate(0px, 0px)' }, { transform: 'translate(0px, 0px)' }], {
//           fill: 'forwards',
//           duration: 0,
//           iterations: 0
//       })
//   });

//   a5 = group5.animate(
//       [{ transform: 'translate(0px, 21.7vh)' }, { transform: 'translate(0px, 0px)' }],
//       {
//           fill: 'forwards',
//           easing: 'linear',
//           duration: 500,
//           iterations: 1
//       }
//   );
  
//   a5.finished.then(() => {
//     a5.commitStyles();
//     a5.cancel();
//     // console.log("A");
//       group5.animate([{ transform: 'translate(0px, 0px)' }, { transform: 'translate(0px, 0px)' }], {
//           fill: 'forwards',
//           duration: 0,
//           iterations: 0
//       })
//   });

//   a6 = group6.animate(
//       [{ transform: 'translate(0px, 17.11vh)' }, { transform: 'translate(0px, 0px)' }],
//       {
//           fill: 'forwards',
//           easing: 'linear',
//           duration: 500,
//           iterations: 1
//       }
//   );
  
//   a6.finished.then(() => {
//     a6.commitStyles();
//     a6.cancel();
//     // console.log("A");
//       group6.animate([{ transform: 'translate(0px, 0px)' }, { transform: 'translate(0px, 0px)' }], {
//           fill: 'forwards',
//           duration: 0,
//           iterations: 0
//       })
//   });

//   a7 = group7.animate(
//       [{ transform: 'translate(0px, 37vh)' }, { transform: 'translate(0px, 0px)' }],
//       {
//           fill: 'forwards',
//           easing: 'linear',
//           duration: 500,
//           iterations: 1
//       }
//   );
  
//   a7.finished.then(() => {
//     a7.commitStyles();
//     a7.cancel();
//     // console.log("A");
//       group7.animate([{ transform: 'translate(0px, 0px)' }, { transform: 'translate(0px, 0px)' }], {
//           fill: 'forwards',
//           duration: 0,
//           iterations: 0
//       })
//   });

//   a8 = group8.animate(
//       [{ transform: 'translate(0px, 31.5vh)' }, { transform: 'translate(0px, 0px)' }],
//       {
//           fill: 'forwards',
//           easing: 'linear',
//           duration: 500,
//           iterations: 1
//       }
//   );
  
//   a8.finished.then(() => {
//     a8.commitStyles();
//     a8.cancel();
//     // console.log("A");
//       group8.animate([{ transform: 'translate(0px, 0px)' }, { transform: 'translate(0px, 0px)' }], {
//           fill: 'forwards',
//           duration: 0,
//           iterations: 0
//       })
//   });

//  // a2 = group2.animate([{
//  //    transform: "translateY(-26.75vh)",
//  //    transform: "translateY(0px)",
//  //  }], {
//  //    fill: 'forwards',
//  //    easing: 'linear',
//  //    duration: 500,
//  //    iterations: 1
//  //  });

//  // a3 = group3.animate([{
//  //    transform: "translateY(-35vh)",
//  //    transform: "translateY(0px)",
//  //  }], {
//  //    fill: 'forwards',
//  //    easing: 'linear',
//  //    duration: 500,
//  //    iterations: 1
//  //  });

//  // a4 = group4.animate([{
//  //    transform: "translateY(-16.66vh)",
//  //    transform: "translateY(0px)",
//  //  }], {
//  //    fill: 'forwards',
//  //    easing: 'linear',
//  //    duration: 500,
//  //    iterations: 1
//  //  });

//  // a5 = group5.animate([{
//  //    transform: "translateY(21.7vh)",
//  //    transform: "translateY(0px)",
//  //  }], {
//  //    fill: 'forwards',
//  //    easing: 'linear',
//  //    duration: 500,
//  //    iterations: 1
//  //  });

//  // a6 = group6.animate([{
//  //    transform: "translateY(17.11vh)",
//  //    transform: "translateY(0px)",
//  //  }], {
//  //    fill: 'forwards',
//  //    easing: 'linear',
//  //    duration: 500,
//  //    iterations: 1
//  //  });

//  // a7 = group7.animate([{
//  //    transform: "translateY(37vh)",
//  //    transform: "translateY(0px)",
//  //  }], {
//  //    fill: 'forwards',
//  //    easing: 'linear',
//  //    duration: 500,
//  //    iterations: 1
//  //  });

//  // a8 = group8.animate([{
//  //    transform: "translateY(31.5vh)",
//  //    transform: "translateY(0px)",
//  //  }], {
//  //    fill: 'forwards',
//  //    easing: 'linear',
//  //    duration: 500,
//  //    iterations: 1
//  //  });

//   a1.pause();
//   a2.pause();
//   a3.pause();
//   a4.pause();
//   a5.pause();
//   a6.pause();
//   a7.pause();
//   a8.pause();  

// }

// const startPlaying = () => {
//     a1.play();
//     a2.play();
//     a3.play();
//     a4.play();
//     a5.play();
//     a6.play();
//     a7.play();
//     a8.play();
// }

// const stopPlaying = () => {
//     a1.pause();
//     a2.pause();
//     a3.pause();
//     a4.pause();
//     a5.pause();
//     a6.pause();
//     a7.pause();
//     a8.pause();
// }

export default function Cover( props ) {
  useEffect(() => {
    var timeout;

    // arrangement();
  
    // function mouseStopped(){
    //   console.log("A");
    //     stopPlaying();
    // }

    let cover = document.querySelector( '.cover-image' ), timer;

    cover.addEventListener(`mousemove`, () => {
        // clearTimeout(timer)
        let statement = document.querySelector( '.cover-statement' );
        statement.classList.add( 'sequence-completed' );
        // cover.classList.add( 'statemen' )
        // startPlaying();
        // timer = setTimeout(mouseStopped, 10);
        // playing = false;
    })

    // let wheelEventEndTimeout = null;
    // cover.addEventListener('wheel', () => {
    //     startPlaying();
    //     clearTimeout(wheelEventEndTimeout);
    //     wheelEventEndTimeout = setTimeout(() => {
    //         stopPlaying();
    //     }, 20);
    // });

    // if( !playing ) return;
    // cover.addEventListener( 'mousemove', startPlaying );
    // cover.addEventListener( 'mouseleave', stopPlaying );

    return () => {
      // if( !playing ) return;
      // cover.addEventListener( 'mousemove', startPlaying );
      // cover.addEventListener( 'mouseleave', stopPlaying );
    };
  });
	

  return (
      <>
  	    <div className={ 'cover-image' }>

          { props.content && (
            <div className={ 'desktop cover-statement text:cover-statement' } dangerouslySetInnerHTML={{ __html: props.content }} />
          )}

          <span className={ 'text:landing uppercase' }>Building</span>
          <span className={ 'text:landing uppercase' }>Ways</span>
          <span className={ 'text:landing uppercase' }>⁂</span>

          <Swiper 
            autoplay={{ delay: 4500 }} 
            loop={true}
            speed={0}
            simulateTouch={false}
            effect={"fade"} 
            modules={[EffectFade]} 
            onInit={() => {
              let cover = document.querySelector( '.cover-image' );
              cover.classList.add( 'cover-image-loaded' )
            }}
            onRealIndexChange={() => {
              let cover = document.querySelector( '.cover-image' );
              let lastClass = cover.classList[ cover.classList.length-1 ];
              if( !optionIndex ) {
                optionIndex = parseInt( lastClass.charAt(lastClass.length - 1), 10);
              }

              if( optionIndex > 4 ) {
                optionIndex = 1;
              } else {
                optionIndex++;
              }

              var regx = new RegExp('\\b' + 'cover-option-' + '.*?\\b', 'g');
              cover.className = cover.className.replace(regx, '');
              cover.classList.add( 'cover-option-' + optionIndex );

            }}
          >
            { props.coverGallery && props.coverGallery.map(( images, i ) => (
              <SwiperSlide key={i}>

                <figure>
                  <GatsbyImage 
                    alt={ images.images.desktop.localFile.altText } 
                    className={ 'cover' } 
                    image={ images.images.desktop.localFile.childImageSharp.gatsbyImageData 
                  } />
                </figure>
              </SwiperSlide>
            )) }
          </Swiper>
        </div>
        <Statement copy={ props.content } />
        <ResearchNavigation />
        <Footer classes={ "mobile" } mobile={ props.footer.footerImageMobile } />
      </>
	);
}